import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserForm from './components/UserForm';
import UserList from './components/UserList';
import TopicsList from './components/TopicsList';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/submit" element={<UserForm />} />
        <Route path="/topics" element={<TopicsList />} />
        <Route path="/list" element={<UserList />} />
        <Route path="/" element={<UserForm />} />
      </Routes>
    </Router>
  );
}

export default App;
