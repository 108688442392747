import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTopics } from '../features/userSlice'; // Adjust import based on your file structure
import TopicCard from './TopicCard'; // Adjust import based on your file structure
import backimage from '../carouselimgs/background.jpg';
import Logo from '../carouselimgs/Logo.png'

const TopicsList = () => {
  const dispatch = useDispatch();
  const topicsData = useSelector((state) => state.users.topics); // Adjust based on your store structure
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const processResponse = (data) => {
    const topicsMap = new Map();

    data.forEach((item) => {
      const { topic_id, topic_name, category_id, category_name } = item;

      if (!topicsMap.has(topic_id)) {
        topicsMap.set(topic_id, {
          topic_id,
          topic_name,
          categories: []
        });
      }

      topicsMap.get(topic_id).categories.push({
        category_id,
        category_name
      });
    });

    return Array.from(topicsMap.values()).sort((a, b) => a.topic_id - b.topic_id); // Sort by topic_id
  };

  useEffect(() => {
    dispatch(fetchTopics())
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  }, [dispatch]);

  useEffect(() => {
    if (topicsData) {
      const processedData = processResponse(topicsData);
      setTopics(processedData);
    }
  }, [topicsData]);

  if (loading) return <Typography variant="h6">Loading...</Typography>;
  if (error) return <Typography variant="h6" color="error">Error loading topics: {error}</Typography>;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${backimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
      }}
    >

<div style={{marginBottom:50}}>

<img width="136" height="64" src={Logo} class="custom-logo"  alt="ANCIPS 2025"></img>

</div>
      <Container>
        <Grid container spacing={2}>
          {topics.map((topic) => (
            <Grid item xs={12} sm={6} md={4} key={topic.topic_id}>
              <TopicCard
                topic={topic} 
                categories={topic.categories}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TopicsList;
