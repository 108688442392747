import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';

const TopicCard = ({ topic, categories }) => {
  // console.log('topic',topic)
  const navigate = useNavigate();

  // Handle navigation to UserList with the topic ID
  const handleCategoryClick = () => {
    if (topic.topic_id) {
      navigate(`/list?topic=${topic.topic_id}`);
    }
  };

  return (
    <Accordion
      sx={{
        backgroundColor: '#f5f5f5',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        marginBottom: 2,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${topic.topic_name}-content`}
        id={`panel-${topic.topic_name}-header`}
        sx={{
          backgroundColor: '#00796b',
          color: '#fff',
          borderRadius: '8px 8px 0 0',
        }}
      >
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          {topic.topic_name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {categories.map((category) => (
            <ListItem
              key={category.category_id}
              disablePadding
              button
              onClick={handleCategoryClick}
            >
              <ListItemText primary={category.category_name} sx={{ color: '#004d40' }} />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default TopicCard;
