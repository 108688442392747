import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, fetchCategories } from '../features/userSlice';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Typography,
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Logo from '../carouselimgs/Logo.png'
const uploadBaseURL =
  process.env.NODE_ENV === "production"
    ? "/uploads/" // Use relative path in production (served from the same server)
    : "http://localhost:5000/uploads/"; // Localhost for development
const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#f5f5f5',  // Light Gray
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));
const StyledCard = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffff',  // White
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  // Light shadow
}));
const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#ffffff',  // White
  borderRadius: theme.shape.borderRadius,
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const UserList = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const categoriesData = useSelector((state) => state.users.categories);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [topicCategories, setTopicCategories] = useState([]);

  // Extract topic ID from URL query params
  const query = new URLSearchParams(useLocation().search);
  const topicId = query.get('topic');

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (topicId) {
      // Filter categories by topic ID
      const filteredCategories = categoriesData.filter((cat) => cat.topic_id == parseInt(topicId));
      setTopicCategories(filteredCategories);
    }
  }, [topicId, categoriesData]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const getCategoryName = (categoryId) => {
    const category = topicCategories.find((cat) => cat.category_id == categoryId);
    return category ? category.category_name : null; // Return null for 'Unknown'
  };

  // Create a Set of category IDs for filtering
  const topicCategoryIds = new Set(topicCategories.map((cat) => cat.category_id));

  // Filter users based on selected category and topic categories
  const filteredUsers = users.filter((user) => {
    const { fullname, email, mobile, organisation, category_id } = user;
    const searchLower = search.toLowerCase();
    const isCategoryMatch = selectedCategory ? category_id == parseInt(selectedCategory) : true;
    const isCategoryInTopic = topicCategoryIds.has(parseInt(category_id));

    return (
      isCategoryInTopic &&
      (
        (fullname && fullname.toLowerCase().includes(searchLower)) ||
        (email && email.toLowerCase().includes(searchLower)) ||
        (mobile && mobile.toLowerCase().includes(searchLower)) ||
        (organisation && organisation.toLowerCase().includes(searchLower))
      ) &&
      (selectedCategory ? category_id == parseInt(selectedCategory) : true)
    );
  });

  return (
    <StyledContainer>
{/* <Typography variant="h4" gutterBottom style={{ fontStyle: 'italic', color: '#a28089' }}>
  Eposter List </Typography> */}

  <img width="136" height="64" src={Logo} class="custom-logo"  alt="ANCIPS 2025"></img>
    <Box mb={2}>
      <StyledTextField
        fullWidth
        label="Search"
        variant="outlined"
        value={search}
        onChange={handleSearch}
      />
    </Box>
    <Box mb={2}>
      <FormControl fullWidth>
        <InputLabel>Select Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          label="Category"
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {topicCategories.map((category) => (
            <MenuItem key={category.category_id} value={category.category_id}>
              {category.category_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
    <StyledCard>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Organisation</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Poster</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.fullname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.mobile}</TableCell>
                <TableCell>{user.organisation}</TableCell>
                <TableCell>{getCategoryName(user.category_id)}</TableCell>
                <TableCell>
                  <Button
                    href={`${uploadBaseURL}/${user.pdf}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    color="primary"
                  >
                    View Poster
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledCard>
    <TablePagination
      component="div"
      count={filteredUsers.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </StyledContainer>
  );
};

export default UserList;
