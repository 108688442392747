import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitUser, fetchCategories } from '../features/userSlice';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Grid,
  Alert,
  Autocomplete,
} from '@mui/material';
import MyCarousel from './MyCarousel';
import backimage from '../carouselimgs/background.jpg';
import Logo from '../carouselimgs/Logo.png'

const UserForm = () => {
  const dispatch = useDispatch();

  const categoriesData = useSelector((state) => state.users.categories); // Adjust based on your store structure
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [pdf, setPdf] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: '', severity: '' });
  const [selectedCategory, setSelectedCategory] = useState(null); // To store the selected category

  useEffect(() => {
    dispatch(fetchCategories()); // Fetch categories when the component is mounted
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!pdf) {
      setAlert({ show: true, message: 'PDF not selected!', severity: 'error' });
      return;
    }

    if (pdf.size > 4 * 1024 * 1024) {
      setAlert({ show: true, message: 'Your PDF file exceeds the 4MB limit!', severity: 'error' });
      return;
    }

    if (!selectedCategory) {
      setAlert({ show: true, message: 'Please select a category!', severity: 'error' });
      return;
    }

    const formData = new FormData();
    formData.append('fullname', fullname);
    formData.append('email', email);
    formData.append('mobile', mobile);
    formData.append('organisation', organisation);
    formData.append('pdf', pdf);
    formData.append('category_id',  selectedCategory.category_id); 

    dispatch(submitUser(formData))
      .then(() => {
        setAlert({ show: true, message: 'E-Poster submitted successfully!', severity: 'success' });
        setFullname('');
        setEmail('');
        setMobile('');
        setOrganisation('');
        setPdf(null);
        setSelectedCategory(null);
      })
      .catch(() => {
        setAlert({ show: true, message: 'Submission failed!', severity: 'error' });
      });
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${backimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
      }}
    >
      {/* <MyCarousel /> */}
      <img width="136" height="64" src={Logo} class="custom-logo"  alt="ANCIPS 2025"></img>

      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            E-Poster Submission Form
          </Typography>
          {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
          <form onSubmit={handleSubmit} style={{marginTop:10}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Full Name"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Organisation"
                  value={organisation}
                  onChange={(e) => setOrganisation(e.target.value)}
                  required
                />
              </Grid>
              {/* Autocomplete with search for categories */}
              <Grid item xs={12}>
                <Autocomplete
                  options={categoriesData} // Use categories data
                  getOptionLabel={(option) => option.category_name} // Display category names
                  value={selectedCategory}
                  onChange={(event, newValue) => setSelectedCategory(newValue)} // Handle category selection
                  renderInput={(params) => (
                    <TextField {...params} label="Select Category" variant="outlined" required />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                >
                  Upload PDF
                  <input
                    type="file"
                    hidden
                    accept="application/pdf"
                    onChange={(e) => setPdf(e.target.files[0])}
                  />
                </Button>
                {pdf && <Typography variant="body2" mt={1}>{pdf.name}</Typography>}
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default UserForm;
