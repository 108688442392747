import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import usersapis from '../apis/usersapis';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await usersapis.get('users');
  return response.data;
});
export const fetchTopics = createAsyncThunk('apple/topics', async () => {
  const response = await usersapis.get('topics');
  return response.data;
});
export const fetchCategories = createAsyncThunk('ball/categories', async () => {
  const response = await usersapis.get('categories');
  return response.data;
});

export const submitUser = createAsyncThunk('users/submitUser', async (formData) => {
  const response = await usersapis.post('submit', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
});

const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    topics : [],
    categories : [],
    status: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchTopics.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTopics.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.topics = action.payload;
      })
      .addCase(fetchTopics.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(submitUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
      });
  },
});

export default userSlice.reducer;
